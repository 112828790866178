import { Atom, SHARED_UTILS, state } from ":mods";
import { createExhibitionIdeaInputs, createPersonalInfoInputs } from "../../form";
import { FormPageProps } from "../../model";
import { Show, batch, createEffect, createSignal } from "solid-js";
import { STUDENT_FORM_STEPS } from "../../const";
import { getCommisions } from "../../api";

export function ContactInfoPage(props: FormPageProps) {
  const $commisions = state.createAsync(getCommisions);
  const defaultDate = SHARED_UTILS.subtractYears(new Date(), 18);
  const [bod, setBOD] = createSignal(
    defaultDate.toLocaleDateString("en-gb", { year: "numeric", month: "long", day: "numeric" })
  );
  const [openCalendar, setOpenCalendar] = createSignal(false);
  const FormInputs = createPersonalInfoInputs();
  FormInputs.DOB.control.setValue(bod() as any);

  const onBODSelected = (date: Date) => {
    setBOD(date.toLocaleDateString("en-gb", { year: "numeric", month: "long", day: "numeric" }));
    FormInputs.DOB.control.setValue(bod() as any);
  };
  const onCountryCodeSelected = (country: string) => {
    FormInputs.CountryCode.control.setValue(country);
  };
  const onSubmit = async (e: Event) => {
    e.preventDefault();
    const values = FormInputs.Actions.getValuesSnakecase();
    values.dob = new Date(values.dob).toLocaleDateString("en-ca") as any;
    props.onFormSubmit(values);
  };

  createEffect(() => {
    const value = FormInputs.WorkForMoc.control.value as "true" | "false";
    // console.log("value is ", value);
    if (value === "true") {
      batch(() => {
        FormInputs.MocEmail.control.markRequired(true);
        FormInputs.WhichCommision.control.markRequired(true);
      });
    } else {
      batch(() => {
        FormInputs.MocEmail.control.markRequired(false);
        FormInputs.WhichCommision.control.markRequired(false);
        FormInputs.MocEmail.control.setValue(undefined);
      });
    }
  });

  return (
    <section class={`flex flex-col justify-center items-center bg#paper w-screen text-16px ${props.class ?? ""}`}>
      <Atom.CircleProgress.CircleProgressbar steps={STUDENT_FORM_STEPS} current_step={1} />
      <h1 class="text-28px font-medium mt-40px mb-10px w-630px ">Please provide us with your details</h1>

      <form class="flex flex-col items-left gap-20px text-16px pt-40px">
        <div class="flex flex-col gap-10px">
          <FormInputs.FirstName.Label title="First Name">
            <FormInputs.FirstName.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.FirstName.Label>
          <FormInputs.FirstName.Input
            type="text"
            class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.LastName.Label title="Last Name">
            <FormInputs.LastName.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.LastName.Label>
          <FormInputs.LastName.Input
            type="text"
            class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.Gender.Label title="Select your gender">
            <FormInputs.Gender.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.Gender.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.Gender.Input type="radio" class="" placeholder="" name="gender" id="male" />
            <p class="text-16px">Male</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.Gender.Input type="radio" class="" placeholder="" name="gender" id="female" />
            <p class="text-16px">Female</p>
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.DOB.Label title="Date of birth">
            <FormInputs.DOB.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.DOB.Label>

          <FormInputs.DOB.Input
            type="text"
            class="text-16px bg-inherit border-2 border#p border-solid px-2.5 py-2 mb-2 w-630px h-50px cursor-pointer"
            placeholder=""
            readOnly
            onClick={() => setOpenCalendar(openCalendar() ? false : true)}
          />
          <Atom.External.DatePicker
            show={openCalendar()}
            value={new Date(bod())}
            initialDate={defaultDate}
            onDateSelected={onBODSelected}
          />
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.MobileNo.Label title="Phone Number">
            <FormInputs.MobileNo.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.MobileNo.Label>
          <FormInputs.MobileNo.PhoneInput
            onCountryCodeSelected={onCountryCodeSelected}
            countryCodeControl={FormInputs.CountryCode.control as any}
            class="border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
          />
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.WorkForMoc.Label title="Do you work for the ministry of culture?">
            <FormInputs.WorkForMoc.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.WorkForMoc.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.WorkForMoc.Input type="radio" class="" placeholder="" name="work" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.WorkForMoc.Input type="radio" class="" placeholder="" name="work" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>
        <Show when={(FormInputs.WorkForMoc.control.value as string) === "true"}>
          <div class="flex flex-col gap-10px">
            <FormInputs.WhichCommision.Label title="Please tell us which commission you work for">
              <FormInputs.WhichCommision.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.WhichCommision.Label>
            <FormInputs.WhichCommision.Select
              class="form-select border-2 border#p !bg#paper border-solid text-0.8rem "
              placeholder=""
              options={$commisions.value}
              // options={["sad"]}
              optionGroupClass={"!text#p "}
            />
          </div>
          <div class="flex flex-col gap-10px">
            <FormInputs.MocEmail.Label title="What is your Ministry of Culture email address?">
              <FormInputs.MocEmail.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.MocEmail.Label>
            <FormInputs.MocEmail.Input
              autocomplete="MocEmail"
              type="email"
              class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
              placeholder=""
            />
          </div>
        </Show>
        <div class="flex justify-end">
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 w-35px h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
